.vm-featured-content {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .vm-featured-content {
    max-width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .vm-featured-content {
    max-width: 990px;
  }
}

@media screen and (min-width: 1200px) {
  .vm-featured-content {
    max-width: 1200px;
  }
}

@media screen and (min-width: 1500px) {
  .vm-featured-content {
    max-width: 1340px;
  }
}
